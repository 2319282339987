<template>
  <section>
    <Header />
    <router-view></router-view>
    <Footer />
  </section>
</template>

<script>
import Header from "./components/Header-Comp.vue";
import Footer from "./components/Footer-Comp.vue";

export default {
  name: "App",
  components: {
    Header,
    Footer,
  },
  data() {
    return {
      entery_modal: true,
      kampanyaModal: false,
      blog_cv_modal: true,
    };
  },
  created() {
    // Sayfa yüklendiğinde dil değerini yerel depolamadan kontrol edin ve ayarlayın
    const savedLocale = localStorage.getItem("lang");
    if (savedLocale) {
      this.$i18n.locale = savedLocale;
    }
  },
};
</script>

